var app = {};

app.init = function() {
    app.heroSlider();
    app.tour();
    app.video();
    app.videopreview();
    app.dropdown();
    app.hamburger();
    app.galleryModal();
    app.smoothScroll();
    app.lyrics();
};

// Home Sliders
app.heroSlider = function() {

    var slider = document.querySelector('.heroSlides');

    if (slider) {

        // For Hero Slider Only 
        var slideIndex = 0;
        showSlides(slideIndex);

        function plusSlides(n) {
            showSlides(slideIndex += n);
        }

        function autoAdvance() {
            plusSlides(1);
        }

        autoAdvance();
        var timerId = setInterval(autoAdvance, 9000);

        $('.hero .sliderPrev').click(function() {
            plusSlides(-1);
            clearInterval(timerId);
        });
        $('.hero .sliderNext').click(function() {
            plusSlides(1);
            clearInterval(timerId);
        });

        function showSlides(n) {
            var i;
            var x = document.getElementsByClassName("heroSlides");
            if (n > x.length) { slideIndex = 1 }
            if (n < 1) { slideIndex = x.length };
            for (i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
            x[slideIndex - 1].style.display = "block";
        }

        var slides = document.querySelectorAll('.heroSlides');
        var slideCount = slides.length;

        if (slideCount > 1) {
            $('.slider').addClass('multiple-slides');
        }
    };
};

app.tour = function() {
    const tourContainer = $('#tourDates');

    $.ajax({
        type: 'GET',
        url: 'https://tour.bluerodeo.com/api/liveevents/bluerodeo',
        success: function(result) {
            parseData(result);
        }
    });

    function parseData(responseData) {
        const events = responseData;
        let items = "";

        if (events.length === 0) $('#noDates').show();

        for (let i = 0; i < events.length; i++) {

            let event = events[i].LiveEvent;

            // Get the Date and put it in a <div>
            let date = formatDate(new Date(event.EventDate.replace(/-/g, '\/').replace(/T.+/, '')));
            let dateWrap = $("<div>");
            dateWrap.addClass('date');
            dateWrap.append(date);

            // Get the Venue and Location Details and put it in a <div>
            //venue
            let venue = event.Venue;
            let venueLink = $("<a>");
            venueLink.attr('href', event.VenueUrl);
            venueLink.attr('target', '_blank');
            venueLink.addClass('location');
            venueLink.append(venue);
            //city
            let city = event.City;
            let region = event.Region;
            let country = event.Country;
            let note = event.EventListNote;
            let cityWrap = $('<span class="city">');
            cityWrap.append(city, `, `);
            if (region) cityWrap.append(region, `, `);
            cityWrap.append(country);
            cityWrap.append('<br>', note);
            //wrap
            let location = $('<div>');
            location.addClass('venue');
            location.append(venueLink, cityWrap);

            //Get Facebook RSVP and put it in a link
            let fbText;
            let fbEvent;
            if (event.FacebookEventId) fbText = "<i class='fab fa-facebook-square'></i> RSVP", fbEvent = "https://www.facebook.com/events/" + event.FacebookEventId;
            let fbLink = $("<a role='button'>");
            fbLink.attr('href', fbEvent);
            fbLink.attr('target', '_blank');
            fbLink.append(fbText);

            // if facebook is included in this tour widget, add fbButton to listItem.append below
            let fbButton = $('<div class="RSVP">');
            if (event.FacebookEventId) fbButton.append(fbLink);

            // Get the Ticket Details and put it in a <button>
            let ticketText;
            let ticketEvent;

            if (event.ExternalTicketUrl) ticketText = "Buy Tickets", ticketEvent = event.ExternalTicketUrl;

            let ticketLink = $("<a role='button'>");

            ticketLink.attr('href', ticketEvent);
            ticketLink.attr('target', '_blank');
            ticketLink.append(ticketText);

            // if ticket purchase button is included in this tour widget, add ticketButton to listItem.append below
            let ticketButton = $('<div class="buyTicket">');
            if (event.ExternalTicketUrl) ticketButton.append(ticketLink);

            let listItem = $("<li>");
            listItem.addClass('tourDate');
            listItem.append(dateWrap, location, fbButton, ticketButton);

            //NOTE listItem is an object so I accessed the property outerHTML to get the <li> html in string form
            let listItemHtml = listItem[0].outerHTML;
            items += listItemHtml;
        }
        addToPage(items);
    }

    function addToPage(items) {
        tourContainer.append(items);
    }

    function formatDate(date) {
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        let day = date.getDate();
        let dayString = ('0' + day).slice(-2);
        let monthIndex = date.getMonth();
        //let year = date.getFullYear();

        return '<span class="month">' + monthNames[monthIndex] + ' </span>' + '<span class="day">' + dayString + '</span>'; // + '<span class="year">' + year + '</span>';
    }
}

//Youtube Video Embed
app.video = function() {

    var div, n,
        v = document.getElementsByClassName("youtube-player-single");
    for (n = 0; n < v.length; n++) {
        div = document.createElement("div");
        div.setAttribute("data-id", v[n].dataset.id);
        div.innerHTML = videoThumb(v[n].dataset.id);
        div.onclick = videoIframe;
        v[n].appendChild(div);
    };

    function videoThumb(id) {
        var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg">',
            play = '<div class="play"></div>';
        return thumb.replace("ID", id) + play;
    };

    function videoIframe() {
        var iframe = document.createElement("iframe");
        var embed = "https://www.youtube.com/embed/ID?autoplay=1";
        iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "1");
        this.parentNode.replaceChild(iframe, this);
    };
};

// variation of video function for landing pages
app.videopreview = function() {

    var div, n,
        v = document.getElementsByClassName("youtube-player-preview");
    for (n = 0; n < v.length; n++) {
        div = document.createElement("div");
        div.setAttribute("data-id", v[n].dataset.id);
        div.innerHTML = videoThumb(v[n].dataset.id);
        v[n].appendChild(div);
    };

    function videoThumb(id) {
        var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg">';
        return thumb.replace("ID", id);
    };
};

//Dropdowns
app.dropdown = function() {
    $('.dropdownContent').hide();
    $('.dropdown').click(function() {
        $(this).siblings('.dropdownContent').slideToggle();
        $(this).find('.caret').toggleClass('fa-caret-up fa-caret-down')
    });
};

//Mobile Hamburger Menu
app.hamburger = function() {
    $('.nav-icon-container').on("click", function() {
        $('.nav-icon').toggleClass('open');
        $("body").toggleClass('mobile-menu-open');
    });
}

app.lyrics = function() {
    $('.lyrics-header').on('click', function() {
        var currentSong = $(this).parent().parent('.each-track');
        $(currentSong).siblings().removeClass('lyrics-showing');
        $(currentSong).toggleClass('lyrics-showing');
    })
}

app.galleryModal = function() {

    var currentIndex = "";

    $('.previewImage').on('click', function() {
        currentIndex = $(this).data('index');
        callFlick(currentIndex);
        launchModal();
    });

    function callFlick(i) {
        $('.main-carousel').flickity({
            cellAlign: 'left',
            contain: true,
            wrapAround: true,
            adaptiveHeight: true,
            imagesLoaded: true,
            initialIndex: i
        });
    };

    function launchModal() {
        $('.gallery-modal').addClass('modal-showing');
    };

    $('.gallery-modal-close').on('click', function() {
        $('.gallery-modal').removeClass('modal-showing');
        $('.main-carousel').flickity('destroy');
    });
}


app.smoothScroll = function() {
    // Select all links with hashes
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });

}


var NOP = NOP || {};

// Should be https for production
NOP.MembershipUrl = 'https://store.davidgray.com';
NOP.AuthenticationCookieName = 'NOPCOMMERCE.AUTH';


NOP.Membership = function(membershipUrl, authenticationCookie) {

    var root = membershipUrl;
    var authenticationCookieName = authenticationCookie;
    var username = '';

    function generateLoginUrl(element) {
        element.attr('href', root + "/login?ReturnUrl=" + encodeURIComponent(location.href));
    };

    function generateLogoutUrl(element) {
        element.attr('href', root + "/logout?ReturnUrl=" + encodeURIComponent(location.href));
    };

    function generateMemberUrl(element) {
        element.attr('href', root + "/customer/info?ReturnUrl=" + encodeURIComponent(location.href));
    };

    function isLoggedIn() {
        username = '';
        var result = false;
        var token = getCookie(authenticationCookieName);
        if (token) {
            result = true;
        }

        return result;
    }

    function isSubscriber(subscriptions) {
        username = '';
        var result = false;
        for (var i = 0; i < subscriptions.length; i++) {
            subscriptions[i] = subscriptions[i].toLowerCase();
        }
        var token = getCookie(authenticationCookieName);
        if (token) {
            var isSubscriberUrl = root + '/webapi/membership/getauthenticatedcustomer';
            $.ajax({
                type: 'GET',
                url: isSubscriberUrl,
                async: false,
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "NOP " + token
                },
                success: (function(response) {
                    username = '';
                    for (var i = 0; i < response.Roles.length; i++) {
                        if ($.inArray(response.Roles[i].toLowerCase(), subscriptions) != -1) {
                            result = true;
                            break;
                        }

                    }
                }),
            });
        }

        return result;
    }

    function getUserName() {
        return username;
    }

    function getCookie(cookieName) {
        var re = new RegExp('[; ]' + cookieName + '=([^\\s;]*)');
        var sMatch = (' ' + document.cookie).match(re);
        if (cookieName && sMatch) return unescape(sMatch[1]);
        return '';
    }

    return {
        GenerateLoginUrl: generateLoginUrl,
        GenerateLogoutUrl: generateLogoutUrl,
        GenerateMemberUrl: generateMemberUrl,
        IsLoggedIn: isLoggedIn,
        IsSubscriber: isSubscriber,
        UserName: getUserName
    };
};

NOP.Membership = NOP.Membership(NOP.MembershipUrl, NOP.AuthenticationCookieName);




$(document).ready(function() {
    var isloggedin = NOP.Membership.IsLoggedIn();
    if (isloggedin) {
        $('[id^=myLinks]').append('<li><a href="#" onclick="NOP.Membership.GenerateLogoutUrl($(this))">SIGN OUT</a></li>');
        $('[id^=myLinks]').append('<li><a href="#" onclick="NOP.Membership.GenerateMemberUrl($(this))">MY ACCOUNT</a></li>');
    } else {
        $('[id^=myLinks]').append('<li><a href="#" onclick="NOP.Membership.GenerateLoginUrl($(this))">SIGN IN</a></li>');
        $('[id^=myLinks]').append('<li><a href="http://store.davidgray.com/register">JOIN</a></li>');
    }

    //call all the app functions
    app.init();

});